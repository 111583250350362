import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import cn from 'classnames'
import { useLocation } from 'preact-iso'

import SearchIcon from '../images/icon-search.svg'

import Button from './button.jsx'
import Input from './new_form/input.jsx'

import s from './front_search_form.module.scss'

const FrontSearchForm = ({ className }) => {
  const { route } = useLocation()

  const onSubmit = useCallback((e) => {
    e.preventDefault()

    const {
      target: { search },
    } = e

    route(`/bilar${search.value ? `?search=${search.value}` : ''}`)
  }, [])

  return (
    <form className={cn(s.base, className)} onSubmit={onSubmit}>
      <Input
        className={s.field}
        icon={`url(${SearchIcon})`}
        design='centerMobile'
        name='search'
        label=''
        placeholder='Sök på modell, utrustning mm'
        showValidity={false}
      />

      <Button className={s.button}>Visa bilar</Button>
    </form>
  )
}

export default FrontSearchForm
