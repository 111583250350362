import { h } from 'preact'
import { useCallback, useReducer, useState } from 'preact/hooks'
import { useDispatch, useSelector } from '@bmp/store/preact'
import { useLocation } from 'preact-iso'
import cn from 'classnames'
import suppress from '@domp/suppress'

import facilities from '../data/facilities.js'

import { formatNumber, formatPrice } from '../util/format_number.js'
import hyphenate from '../util/hyphenate.js'
import calculateMonthly from '../util/calculate_monthly.js'
import useIsomorphicEffect from '../util/use_isomorphic_effect.js'
import isMobile from '../util/is_mobile.js'
import { fetchCar, unloadCar } from '../store/car_actions.js'

import Button from './button.jsx'
import Dropdown from './dropdown.jsx'
import Finance from './finance.jsx'
import GoogleMaps from './google_maps.jsx'
import Head from './head.jsx'
import ImageGallery from './image_gallery.jsx'
import InterestForm from './interest_form.jsx'
import Modal from './modal.jsx'
import ReserveCarForm from './reserve_car_form.jsx'

import s from './bil_page.module.scss'

const BilPage = () => {
  const { path } = useLocation()
  const dispatch = useDispatch()
  const { car } = useSelector(['car', 'location'])
  const [showModal, toggleModal] = useReducer((current) => !current, false)
  const [visibleTab, setVisibleTab] = useState('equipment')

  // console.log('car', car)
  if (!car) {
    const match = path.match(/[^/]+\/?$/)

    if (match) {
      const [id] = match

      throw dispatch(fetchCar(id))
    }
  }

  useIsomorphicEffect(() => {
    return () => dispatch(unloadCar())
  }, [])

  const onShare = useCallback((e) => {
    if (isMobile()) {
      suppress(e)

      navigator.share({ url: window.location.href })
    }
  }, [])

  if (car == null) {
    return null
  }

  // TODO
  if (car instanceof Error || car.name === 'FetchError') {
    return (
      <section className={s.base}>
        <div className={s.container}>
          <div className={s.alreadySold}>Den här bilen har tyvärr redan sålts</div>
        </div>
      </section>
    )
  }

  const facility = facilities[car.location.toLowerCase()]
  const title = `${car.brand} ${car.model}`
  const subtitle = `${car.brand} ${car.model} ${car.configuration}`
  const href = `${location.protocol}:${location.host}${location.hostname}${location.search}`

  return (
    <section className={s.base} typeof='Car'>
      <Head>
        <title> : {title}</title>
        <meta property='og:locale' content='sv_SE' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={subtitle} />
        <meta property='og:image' content={car.images[0]} />
        <meta property='og:url' content={href} />
      </Head>

      <header className={s.header}>
        <h1 className={s.model}>
          <span>{title}</span>
        </h1>

        <a className={s.location} href='https://maps.app.goo.gl/tds1L3ukKECeEySE6' target='_blank'>
          {car.location}
        </a>

        <Dropdown className={s.share}>
          <Dropdown.Toggle>
            <div className={s.icon} onClick={onShare}>
              <span>Dela</span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={s.menu}>
            <a className={s.facebook} href={`https://www.facebook.com/sharer/sharer.php?u=${href}`} target='_blank'>
              Facebook
            </a>
            <a
              className={s.linkedin}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${href}`}
              target='_blank'
            >
              LinkedIn
            </a>
            <a className={s.twitter} href={`http://www.twitter.com/share?text=${href}`} target='_blank'>
              Twitter
            </a>
            <a
              className={s.email}
              href={`mailto:?subject=${subtitle}&body=Hej! Jag har hittat en bil hos Carson i Vallentuna jag tror du kan vara intresserad av - https://www.riddermarkbil.se/kopa-bil/volvo/yyp004/`}
              target='_blank'
            >
              E-post
            </a>
            <a className={s.copy}>Kopiera länk</a>
          </Dropdown.Menu>
        </Dropdown>

        <p className={s.configuration} property='name'>
          {car.configuration}
        </p>
      </header>

      <ImageGallery className={s.images} imageTitle={subtitle} images={car.images} />

      <section className={s.specs}>
        <h1>Basfakta</h1>

        <div className={s.items}>
          <div className={s.item}>
            <div className={s.label}>Registreringsnummer</div>
            <div className={s.value}>{car.regNumber}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Modellår</div>
            <div className={s.value}>{car.modelDate}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Mätarställning</div>
            <div className={s.value}>
              {formatNumber(car.mileage)} mil
              <meta property='value' content={car.mileage} />
              <meta property='unitCode' content='KMT' />
            </div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Växellåda</div>
            <div className={s.value}>{car.transmission}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Drivmedel</div>
            <div className={s.value}>{car.fuelType}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Effekt</div>
            <div className={s.value}>{car.power} hk</div>
          </div>
          {car.fuelConsumption ? (
            <div className={s.item}>
              <div className={s.label}>Förbrukning</div>
              <div className={s.value}>{car.fuelConsumption} L/mil</div>
            </div>
          ) : null}
          {car.speed ? (
            <div className={s.item}>
              <div className={s.label}>Toppfart</div>
              <div className={s.value}>{car.speed} km/h</div>
            </div>
          ) : null}
          {car.emissions ? (
            <div className={s.item}>
              <div className={s.label}>CO2 utsläpp</div>
              <div className={s.value}>{car.emissions} g/km</div>
            </div>
          ) : null}
          {car.meetsEmissionStandard ? (
            <div className={s.item}>
              <div className={s.label}>Miljöklass</div>
              <div className={s.value}>{car.meetsEmissionStandard}</div>
            </div>
          ) : null}
          <div className={s.item}>
            <div className={s.label}>Fordonstyp</div>
            <div className={s.value}>{car.bodyType}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Färg</div>
            <div className={s.value}>{car.color}</div>
          </div>
          {car.seatingCapacity && (
            <div className={s.item}>
              <div className={s.label}>Sittplatser</div>
              <div className={s.value}>{car.seatingCapacity}</div>
            </div>
          )}
          <div className={s.item}>
            <div className={s.label}>Pris</div>
            <div className={s.value}>
              <strong>{formatPrice(calculateMonthly(car.price))}/mån</strong>&nbsp;eller&nbsp;
              <strong>{formatPrice(car.price)}</strong>
              <meta property='price' content={car.price} />
              <meta property='priceCurrency' content='SEK' />
              <meta property='seller' resource='/#us' />
            </div>
          </div>
        </div>

        {false && (
          <Button className={s.button} fullWidth onClick={toggleModal}>
            Reservera bilen
          </Button>
        )}
        <Button className={s.button} fullWidth invert href='#interest-form'>
          Kontakta säljare
        </Button>
      </section>

      <div className={s.tabSelector}>
        <Button
          design='none'
          className={cn(s.tabButton, visibleTab === 'equipment' && s.current)}
          onClick={() => setVisibleTab('equipment')}
        >
          Utrustning
        </Button>
        <Button
          design='none'
          className={cn(s.tabButton, visibleTab === 'description' && s.current)}
          onClick={() => setVisibleTab('description')}
        >
          Beskrivning
        </Button>
        <Button
          design='none'
          className={cn(s.tabButton, visibleTab === 'varFinnsBilen' && s.current)}
          onClick={() => setVisibleTab('varFinnsBilen')}
        >
          Var finns bilen?
        </Button>
      </div>

      <section className={cn(s.equipment, visibleTab === 'equipment' && s.visible)}>
        <h1>Utrustning</h1>

        <ul>{car.equipment && car.equipment.map((equipment) => <li>{hyphenate(equipment)}</li>)}</ul>
      </section>

      <section className={cn(s.description, visibleTab === 'description' && s.visible)}>
        <h1>Beskrivning</h1>

        <p>{car.description}</p>
      </section>

      <section className={cn(s.varFinnsBilen, visibleTab === 'varFinnsBilen' && s.visible)}>
        <h1>Var finns bilen?</h1>

        <div className={s.address}>
          <p>
            <strong property='legalName'>Carson {car.location}</strong>
            <br />
            <span property='address' typeof='PostalAddress'>
              <span property='streetAddress'>{facility.address.street}</span>
              <br />
              <span property='postalCode'>{facility.address.postalCode}</span>{' '}
              <span property='addressLocality'>{facility.address.locality}</span>
            </span>
          </p>
          <p className={s.telephone} property='telephone'>
            {facility.telephone}
          </p>
        </div>

        <GoogleMaps className={s.map} latitude={facility.latitude} longitude={facility.longitude} />
      </section>

      <section className={s.finance}>
        <Finance className={s.component} price={car.price} />
      </section>

      <section className={s.interestForm}>
        <h1 id='interest-form'>Intresseanmälan {title}</h1>
        <hr />

        <p className={s.subheading}>Kontakta oss om du är intresserad av denna bil!</p>

        <InterestForm carId={car.id} className={s.form} />
      </section>

      <Modal open={showModal} onClose={toggleModal}>
        {showModal && <ReserveCarForm car={car} />}
      </Modal>
    </section>
  )
}

export default BilPage
