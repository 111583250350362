import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import cn from 'classnames'
import rek from 'rek'

import useRequestState from '../../shared/hooks/use_request_state.js'
import serializeForm from '../../shared/utils/serialize_form.js'

import Input from './new_form/input.jsx'
import Button from './button.jsx'
import Message from './message.jsx'

import s from './newsletter_form.module.scss'

const NewsletterForm = ({ className }) => {
  const [{ error, pending, success }, actions] = useRequestState()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    actions.pending()

    const form = e.currentTarget

    try {
      await rek.post(form.action, serializeForm(e.currentTarget))

      form.reset()

      actions.success()
    } catch (err) {
      actions.error(err)
    }
  }, [])

  return (
    <form className={cn(s.base, className)} method='post' action='/api/newsletter-emails' onSubmit={onSubmit}>
      <div className={s.content}>
        {error && (
          <Message type='error' className={cn(s.notification, s.error)} noMargin>
            <strong>Åh nej!</strong>
            <p>Något gick tyvärr fel, pröva gärna igen och kontakta oss om felet kvarstår.</p>
          </Message>
        )}

        {success && (
          <Message type='success' className={cn(s.notification)}>
            <strong>
              Tack! Din epost är registrerad till vårt nyhetsbrev! Du kan enkelt avregistrera dig när du får första
              utskicket.
            </strong>
          </Message>
        )}

        <Input className={s.input} type='email' name='email' label='Epostadress' placeholder='' required />

        <Button disabled={pending} className={s.button} type='submit'>
          Prenumera
        </Button>
      </div>
    </form>
  )
}

export default NewsletterForm
