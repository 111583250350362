import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import cn from 'classnames'
import rek from 'rek'

import useRequestState from '../../shared/hooks/use_request_state.js'
import serializeForm from '../../shared/utils/serialize_form.js'

import Input from './new_form/input.jsx'
import Textarea from './new_form/textarea.jsx'
import Button from './button.jsx'
import Message from './message.jsx'

import s from './contact_form.module.scss'

const ContactForm = ({ className }) => {
  const [{ error, pending, success }, actions] = useRequestState()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    actions.pending()

    const form = e.currentTarget

    try {
      await rek.post(form.action, serializeForm(e.currentTarget))

      form.reset()

      actions.success()
    } catch (err) {
      actions.error(err)
    }
  }, [])

  return (
    <form className={cn(s.base, className)} method='post' action='/api/send/contact' onSubmit={onSubmit}>
      {error && (
        <Message type='error' className={cn(s.notification, s.error)} noMargin>
          <strong>Ojdå! Något blev fel. Vänligen försök igen.</strong>
        </Message>
      )}
      {success && (
        <Message type='success' className={s.notification}>
          <strong>Skickat! Tack för ditt meddelande. Vi hör av oss inom kort.</strong>
        </Message>
      )}
      <Input className={s.name} type='text' name='name' label='Namn' placeholder='' required />
      <Input className={s.email} type='email' name='email' label='Epost' placeholder='' required />
      <Textarea name='message' className={s.message} label='Meddelande' placeholder='' rows='8' required />

      <Button disabled={pending} className={s.button}>
        Skicka
      </Button>
    </form>
  )
}

export default ContactForm
