import { h } from 'preact'
import rek from 'rek'
import { useCallback } from 'preact/hooks'
import cn from 'classnames'

import useRequestState from '../../shared/hooks/use_request_state.js'
import serializeForm from '../../shared/utils/serialize_form.js'

import Input from './new_form/input.jsx'
import Select from './new_form/select.jsx'
import Textarea from './new_form/textarea.jsx'
import Button from './button.jsx'
import Message from './message.jsx'

import s from './workshop_form.module.scss'

const options = ['Verkstad', 'Service', 'Rekond']

const WorkshopForm = ({ className }) => {
  const [{ error, pending, success }, actions] = useRequestState()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    actions.pending()

    const form = e.currentTarget

    try {
      await rek.post(form.action, serializeForm(e.currentTarget))

      form.reset()

      actions.success()
    } catch (err) {
      actions.error(err)
    }
  }, [])

  return (
    <form className={cn(s.base, className)} onSubmit={onSubmit} action='/api/send/workshop'>
      <label className={s.label}>Vad behöver du hjälp med idag?</label>
      <Select design='noLabel' name='help' id='select' placeholder='Välj...' options={options} required />
      <h1>Kontaktuppgifter</h1>
      <Input className={s.givenName} name='givenName' label='Förnamn' placeholder='' required />
      <Input className={s.familyName} name='familyName' label='Efternamn' placeholder='' required />
      <Input className={s.telephone} name='telephone' label='Telefon' placeholder='' required />
      <Input className={s.email} name='email' label='Email' placeholder='' required />
      <h1>Biluppgifter</h1>
      <Input className={s.regNumber} name='regNumber' label='Registreringsnummer' placeholder='' required />
      <Input className={s.odometer} name='odometer' label='Mätarställning' placeholder='' required />
      <Textarea className={s.message} name='message' label='Meddelande' placeholder='' />
      {error && (
        <Message type='error' className={cn(s.notification, s.error)} noMargin>
          <strong>Ojdå! Något blev fel. Vänligen försök igen.</strong>
        </Message>
      )}
      {success && (
        <Message type='success' className={s.notification}>
          <strong>Tack! Vi hör av oss inom kort.</strong>
        </Message>
      )}
      <Button disabled={pending} className={s.button}>
        Skicka
      </Button>
    </form>
  )
}

export default WorkshopForm
