import { h } from 'preact'
import cn from 'classnames'
import { useSelector } from '@bmp/store/preact'

import facilities from '../data/facilities.js'

import Button from './button.jsx'
import Head from './head.jsx'
import GoogleMaps from './google_maps.jsx'
import WorkshopForm from './workshop_form.jsx'

import s from './verkstad_page.module.scss'
import sLayout from './layout.module.scss'

const VerkstadPage = ({ route }) => {
  const { organization } = useSelector(['organization'])

  return (
    <section className={cn(sLayout.page, s.base)}>
      <Head>
        <title> : {route.title}</title>
      </Head>

      <header className={s.header}>
        <h1>Boka service/rekond</h1>
        <p>
          Hos oss på Carson så erbjuder vi att även serva och ta hand om din befintliga bil. Det gör vi i vår egen
          verkstad och rekond.
        </p>
      </header>

      <WorkshopForm className={s.form} />

      <div className={s.locationWrapper}>
        <section className={s.location}>
          <div className={s.text}>
            <h1>Vår verkstad</h1>
            <p>
              Hos oss på Carson så erbjuder vi att även serva och ta hand om din befintliga bil. Det gör vi i vår egen
              verkstad och rekond.
            </p>

            <p>Vi erbjuder även fri lånebil till lågt pris.</p>
            <Button href='/kontakt'>Hitta hit</Button>
          </div>
          <GoogleMaps
            className={s.map}
            latitude={facilities.vallentuna.latitude}
            longitude={facilities.vallentuna.longitude}
          >
            {organization && (
              <div className={s.popover}>
                <img src='/img/DSC_0012_800.jpg' />
                <div className={s.text}>
                  <strong>{organization.legalName}</strong>
                  <br />
                  <span property='streetAddress'>{organization.streetAddress}</span>
                  <br />
                  <span property='postalCode'>{organization.postalCode}</span>{' '}
                  <span property='addressLocality'>{organization.locality}</span>
                  <br />
                  <span property='addressCountry'>{organization.country}</span>
                </div>
              </div>
            )}
          </GoogleMaps>
        </section>
      </div>

      <section className={s.mechanics}>
        <div className={s.text}>
          <h1>Kompetenta servicetekniker</h1>
          <p>Vi har mycket kompententa servicetekniker Anton och Marcus som bryr sig om din bil!</p>
          <Button href='/kontakt'>Kontakta oss</Button>
        </div>

        <img src='/img/photo_verkstad_anton_800.jpg' />
        <img src='/img/photo_verkstad_marcus_800.jpg' />
      </section>
    </section>
  )
}

export default VerkstadPage
