import { h } from 'preact'
import { useDispatch, useSelector } from '@bmp/store/preact'

import facilities from '../data/facilities.js'
import { useTranslate } from '../hooks/translate'
import { fetchEmployees } from '../store/employees_actions.js'

import Head from './head.jsx'

import Employee from './employee.jsx'
import GoogleMaps from './google_maps.jsx'
import ContactForm from './contact_form.jsx'

import s from './kontakt_page.module.scss'

const KontaktPage = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { employees } = useSelector(['employees', 'openingHours', 'organization'])

  if (!employees) {
    throw dispatch(fetchEmployees())
  }

  return (
    <section className={s.base}>
      <Head>
        <title> : Kontakt</title>
      </Head>

      <header className={s.header}>
        <h1 className={s.pageHeading}>{translate('Kontakt')}</h1>
        <p>
          Har du några frågor eller funderingar är vi redo att hjälpa dig på alla sätt vi kan. Kontakta oss eller ännu
          bättre; besök oss i butiken!
        </p>
      </header>
      <div className={s.optionsWrapper}>
        <div className={s.options}>
          <section className={s.visit}>
            <h1>Besök oss</h1>
            <p>Kom gärna förbi butiken. Vi har ett brett utbud av bilar som väntar på dig!</p>
            <p className={s.address}>
              <a href='https://maps.app.goo.gl/qG4MyDcLPB2P2edb6' target='_blank'>
                Fågelsångsvägen 8, 186 42 Vallentuna
              </a>
            </p>
          </section>
          <div className={s.divider} />
          <section className={s.call}>
            <h1>Ring oss</h1>
            <p>Har du frågor eller vill veta mer? Tveka inte att ringa oss. Vi finns här för att hjälpa dig.</p>
            <p>
              <a href='tel:08 - 522 927 00'>08 - 522 927 00</a>
            </p>
          </section>
          <div className={s.divider} />
          <section className={s.email}>
            <h1>Mejla oss </h1>
            <p>Du kan även mejla oss för att få mer information. Vi ser fram emot att hör från dig.</p>
            <p>
              <a href='mailto:info@carson.nu'>info@carson.nu</a>
            </p>
          </section>
        </div>
      </div>

      <section className={s.employees}>
        <h1>{translate('Våra medarbetare')}</h1>

        <h2 class={s.heading}>Säljare</h2>
        {employees && employees.slice(0, 4).map((employee) => <Employee {...employee} className={s.employee} />)}
        <h2 class={s.heading}>Service/Logistik</h2>
        {employees && employees.slice(4, 6).map((employee) => <Employee {...employee} className={s.employee} />)}
        <h2 class={s.heading}>Administration</h2>
        {employees &&
          employees.slice(-1).map((employee) => <Employee {...employee} telephone='' className={s.employee} />)}
      </section>

      <div className={s.contactFormWrapper}>
        <section className={s.contactForm}>
          <h1>Vi vill gärna hör av er!</h1>
          <hr />
          <p>Vill du inte öppna upp någon epost klient kan du använda formuläret nedan.</p>
          <ContactForm className={s.form} />
        </section>
      </div>

      <GoogleMaps
        className={s.map}
        latitude={facilities.vallentuna.latitude}
        longitude={facilities.vallentuna.longitude}
      />
    </section>
  )
}

export default KontaktPage
