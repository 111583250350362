import { h } from 'preact'
import { useCallback, useReducer } from 'preact/hooks'
import cn from 'classnames'
import rek from 'rek'

import useRequestState from '../../shared/hooks/use_request_state.js'
import serializeForm from '../../shared/utils/serialize_form.js'

import Input from './new_form/input.jsx'
import Checkbox from './new_form/checkbox.jsx'
import Button from './button.jsx'
import Message from './message.jsx'

import s from './interest_form.module.scss'

const InterestForm = ({ carId, className }) => {
  const [hasCar, toggleHasCar] = useReducer((current) => !current, false)
  const [{ error, pending, success }, actions] = useRequestState()

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    actions.pending()

    const form = e.currentTarget

    try {
      await rek.post(form.action, serializeForm(e.currentTarget))

      form.reset()

      actions.success()
    } catch (err) {
      actions.error(err)
    }
  }, [])

  return (
    <form className={cn(s.base, className)} method='post' action='/api/send/interest' onSubmit={onSubmit}>
      <input type='hidden' name='carId' value={carId} />
      <Input type='text' name='name' id='test' label='Namn' placeholder='' required />
      <Input type='email' name='email' label='Email' placeholder='' required />
      <Input type='text' name='telephone' label='Telephone' placeholder='' className={s.telephone} required />

      <Checkbox label='Jag har inbytesbil' onChange={toggleHasCar} className={s.checkbox} />
      {hasCar && <p className={s.text}>Skriv in registreringsnumret på bilen så kollar vi värdet</p>}

      {error && (
        <Message type='error' className={cn(s.notification, s.error)} noMargin>
          <strong>Åh nej!</strong>
          <p>Något gick tyvärr fel, pröva gärna igen och kontakta oss om felet kvarstår.</p>
        </Message>
      )}
      {success && (
        <Message type='success' className={cn(s.notification)}>
          <strong>Tack! Intresseanmälan skickad. Vi hör av oss inom kort.</strong>
        </Message>
      )}

      <Button disabled={pending} className={s.button} type='submit'>
        Skicka
      </Button>

      {hasCar
        ? [<Input className={s.regNumber} name='regNumber' label='Registreringsnummer' placeholder='' required />]
        : null}
    </form>
  )
}

export default InterestForm
